import { getKlubak } from '../api/matx';
import { useAsync } from '../hooks';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ROUTES } from '../config';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { Pagination, Loader } from '../components';

const Clubs = () => {
  const { triggerFunction, data, loading, loaded } = useAsync();
  const page_size = 24;
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get('page') ? parseInt(searchParams.get('page')) : 1,
  );
  useEffect(() => {
    triggerFunction(getKlubak, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <h1 className="documentFirstHeading" id="title">
        <FormattedMessage id="Klubak" defaultMessage="Klubak" />
      </h1>
      <div id="column-content">
        {loading && !loaded && <Loader />}
        <div className="row">
          {loaded &&
            data.results.map((club, key) => {
              return (
                <div className="col-md-4 col-lg-3 col-xl-2" key={key}>
                  <div className="card-kluba">
                    <div className="img-container">
                      <img
                        src={
                          club?.logo?.url ||
                          'https://via.placeholder.com/200x200.jpg'
                        }
                        alt={club.detalleclub}
                      />
                    </div>
                    <h5 className="kluba-title">
                      <Link to={`/${ROUTES.CLUBS.path}/${club.nid}`}>
                        {club.detalleclub}
                      </Link>
                    </h5>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {loaded && (
        <Pagination
          page={page}
          setPage={setPage}
          setSearchParams={setSearchParams}
          page_size={page_size}
          count={data.count}
          previous={data.previous}
          next={data.next}
        />
      )}
    </>
  );
};

export default Clubs;

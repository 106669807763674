import {
  getErredakziokoak,
  // getAplikaziokoak,
  // getArtikuluak,
} from '../api/matx';
import { Modal, Pagination, Card, Loader } from '../components';
import { useAsync } from '../hooks';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

const News = () => {
  const { triggerFunction, data, loading, loaded } = useAsync();
  const page_size = 10;
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get('page') ? parseInt(searchParams.get('page')) : 1,
  );

  useEffect(() => {
    triggerFunction(getErredakziokoak, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Modal show={showModal} setShowModal={setShowModal} />
      <h1 className="documentFirstHeading">
        <FormattedMessage id="Albisteak" defaultMessage="Albisteak" />
      </h1>
      <div id="column-content">
        {loading && !loaded && <Loader />}
        {loaded && page === 1 ? (
          <>
            <div className="row">
              <div className="col-md-6 left-teaser">
                <Card item={data.results[0]} setShowModal={setShowModal} />
              </div>
              <div className="col-md-6 right-teaser">
                <Card item={data.results[1]} setShowModal={setShowModal} />
              </div>
            </div>
            <div className="row">
              {data.results.slice(2).map((news_item) => {
                return (
                  <div className="col-md-3">
                    <Card item={{ ...news_item }} setShowModal={setShowModal} />
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          loaded && (
            <div className="row">
              {data.results.map((news_item) => {
                return (
                  <div className="col-md-4">
                    <Card item={{ ...news_item }} setShowModal={setShowModal} />
                  </div>
                );
              })}
            </div>
          )
        )}
        {loaded && (
          <Pagination
            page={page}
            setPage={setPage}
            setSearchParams={setSearchParams}
            page_size={page_size}
            count={data.count}
            previous={data.previous}
            next={data.next}
          />
        )}
      </div>
    </div>
  );
};

export default News;

import React, { useState } from 'react';
import { useAsync } from '../hooks';
import { postHarremanetarako } from '../api/matx';
import { ROUTES } from '../config';
const Harremanetarako = () => {
  const { triggerFunction, data, loading, loaded } = useAsync();
  const [izena, setIzena] = useState('');
  const [elkartea, setelkartea] = useState('');
  const [telefonoa, settelefonoa] = useState('');
  const [email, setemail] = useState('');
  const [mezua, setmezua] = useState('');
  const [pribatutasuna, setpribatutasuna] = useState(false);
  const [formstatus, setformstatus] = useState(0);

  const onSubmit = () => {
    if (!pribatutasuna) {
      setformstatus(-1);
    } else {
      setformstatus(0);
      triggerFunction(postHarremanetarako, {
        contact_izena: izena,
        contact_elkartea: elkartea,
        contact_telefonoa: telefonoa,
        contact_email: email,
        contact_mezua: mezua,
      });
    }
  };
  return (
    <div className="page">
      <h1 className="documentFirstHeading">Harremanetarako</h1>
      <div id="column-content">
        {loaded && (
          <div>
            {data.success === 1 ? (
              <div className="alert alert-success" role="alert">
                <strong>Mezua ondo bidali da!</strong>
              </div>
            ) : data.success === 0 ? (
              <div className="alert alert-danger" role="alert">
                <strong>Ezin izan da mezua bidali:</strong>
                <p>
                  {data.msg
                    .replace('contact_izena', 'Izen Abizenak eremua')
                    .replace(',contact_telefonoa', ', Telefono zenbakia eremua')
                    .replace('contact_email', 'Email eremua')
                    .replace(',contact_mezua', ', Mezua eremua')}
                </p>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
        {formstatus === -1 && (
          <div className="alert alert-danger" role="alert">
            Ez dituzu pribatutasun baldintzak onartu!
          </div>
        )}
        <form
          className="contact-form"
          onSubmit={(data) => {
            data.preventDefault();
            onSubmit();
          }}
          action=""
        >
          <div className="row">
            <div className="col-md-6">
              <input
                type="text"
                id="contact_izena"
                className="input"
                value={izena}
                onChange={(e) => setIzena(e.target.value)}
                name="contact_izena"
                data-required_mark="required"
                data-field_type="input"
                data-original_id="izena"
                placeholder="Izen Abizena"
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                id="contact_elkartea"
                className="input"
                value={elkartea}
                onChange={(e) => setelkartea(e.target.value)}
                name="contact_elkartea"
                data-field_type="input"
                data-original_id="elkartea"
                placeholder="Kirol Elkartearen izena"
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                id="contact_telefonoa"
                className="input"
                value={telefonoa}
                onChange={(e) => settelefonoa(e.target.value)}
                name="contact_telefonoa"
                data-field_type="input"
                data-original_id="telefonoa"
                placeholder="Telefono zenbakia"
              />
            </div>
            <div className="col-md-6">
              <input
                type="email"
                id="contact_email"
                className="input"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                name="contact_email"
                data-required_mark="required"
                data-field_type="email"
                data-original_id="email"
                placeholder="Email"
              />
            </div>
            <div className="col-12">
              <textarea
                name="contact_mezua"
                id="contact_mezua"
                className="contact_message input"
                value={mezua}
                onChange={(e) => setmezua(e.target.value)}
                data-required_mark="required"
                data-field_type="text"
                data-original_id="mezua"
                placeholder="Mezua"
              ></textarea>
            </div>
            <div className="col-12">
              <span className="contact_field_checkbox">
                <input
                  type="checkbox"
                  id="contact_field"
                  className="input"
                  checked={pribatutasuna}
                  onClick={(e) => setpribatutasuna(e.target.checked)}
                  value="Pribatutasun politika"
                />
                <label htmlFor="contact_field">
                  <i>
                    <a
                      href={`/${ROUTES.LEGEOHARRA.path}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Pribatutasun politika
                    </a>{' '}
                    onartzen dut
                  </i>
                </label>
              </span>
            </div>
            <div className="col-12 text-lg-end">
              {!loading && (
                <button type="submit" className="contact_submit button">
                  BIDALI
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Harremanetarako;

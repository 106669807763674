import { getStaticPage } from '../api/matx';
import { Loader } from '../components';
import { useAsync } from '../hooks';
import React, { useEffect } from 'react';
// import { FormattedMessage } from 'react-intl';

const StaticPage = (api, title) => {
  const { triggerFunction, data, loading, loaded } = useAsync();
  useEffect(() => {
    if (api) {
      triggerFunction(getStaticPage, api);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFunction]);

  return (
    <div>
      <h1 className="documentFirstHeading">{loaded && title}</h1>
      {loaded && <div dangerouslySetInnerHTML={{ __html: data.get_body }} />}
      {loading && !loaded && <Loader />}
    </div>
  );
};

export default StaticPage;

import React from 'react';
const Legeoharra = () => {
  return (
    <div className="page">
      <h1 className="documentFirstHeading">Lege oharra</h1>
      <div id="column-content">
        <p>
          Indarrean dagoen legedia betez, jakinarazi nahi dizugu matx.eus
          (aurrerantzean “webgunea”), interneteko domeinuen titularra honako hau
          dela:
        </p>

        <ul>
          <li>
            Izen soziala: Tokikom, S.L. (aurrerantzean “Tokikom” edo
            “Tratamenduaren Erantzulea“).
          </li>
          <li>
            I.F.Z.: B75069864 o Helbidea: Agoitz Plaza, 1, 48015 Bilbao
            (Bizkaia)
          </li>
          <li>Telefono zenbakia: 946 62 02 39</li>
          <li>Helbide elektronikoa: info@matx.eus</li>
        </ul>
        <p>
          Tokikom enpresak komunikazioa eta web zerbitzuak, zerbitzu
          informatikoak eta hauekin lotutako zerbitzuak ematen ditu. matx.eus
          Tokikomen jabetzako webgunea da eta berau bisitatu eta erabiltzen
          dituen pertsona, webgunearen erabiltzailea da (aurrerantzean
          “erabiltzailea”). Tokikomek “Tratamenduaren Erantzule” gisa jarduten
          du webgune honen bidez egiten diren datu pertsonalen tratamenduei
          dagokienez. Baina Tokikom enpresak kanpoko webguneetarako edo sare
          sozialetarako albisteak eta estekak argitaratzen dituen kasuetan, bere
          eginkizuna informatzea besterik ez da izango. Kasu horietan, albisteen
          eta esteken edukiekin lotuta gerta daitezkeen datu pertsonalen
          tratamenduak ez dira Tokikomen titulartasunekoak izango.
        </p>
        <h3>1. Helburuak</h3>
        <p>Webgunearen helburu nagusiak honako hauek dira:</p>
        <ul>
          <li>Euskarazko kirol komunitate berria sortzea</li>
          <li>Tokian tokiko kirola dinamizatzea</li>
          <li>Kirolzale, kirolari eta kluben arteko harremanak erraztea</li>
        </ul>
        <h3>2. Baldintza Orokorrak</h3>
        <p>
          Erabiltzailea borondatez sartu ahal izango da bertan, eta gainera,
          sartzea doakoa da. Webgune honetan nabigatzeak esan nahi du
          erabiltzaileak ezagutzen eta onartzen dituela bertako lege oharrak,
          erabilera baldintzak eta bertako pribatutasun politika. Erabiltzaileak
          ez baditu onartzen pribatutasun politika, cookie politika eta
          erabilera baldintzak hauek, eta beraz, ez badie onespena ematen,
          ezingo du webgune honetan nabigatu.
        </p>
        <p>
          Webgune honetan sartzea eta bertako informazioari zeinahi erabilera
          ematea erabiltzailearen eta soilik erabiltzailearen erantzukizunpekoak
          izango dira. Hala, informazioa webgunearen helburuarekin bat eginda
          erabiltzeko konpromisoa hartu beharko du erabiltzaileak.
          Erabiltzaileak ezingo dio eman, beraz, legez kontrako erabilerarik
          webguneko informazioari. Bide beretik, ezingo du webguneko
          informatika-sistemei kaltea eragin diezaiekeen ekintzarik egin.
        </p>
        <p>
          Erabiltzaileek galarazita izango dute eduki faltsuak edo okerrak
          txertatzea edo jakinaraztea, ezingo diote eman Tokikomeri, beste
          erabiltzaile batzuk edo hirugarrenak nahasteko moduko edukirik. Ezin
          da erabili hirugarrenen datu pertsonalik, euren baimenik gabe. Era
          berean, debekatuta dago hirugarrenen identifikazio-datuak erabiltzea,
          beste pertsona edo entitate baten tokian jartzeko asmoz. Erabiltzailea
          bera izango da, horrenbestez, sortzen dituen kalteen erantzule
          bakarra.
        </p>
        <h3>3. Bermeak eta erantzukizunak kentzea</h3>
        <p>
          Tokikomek ahalegin handia egiten du webgunean argitaratzen diren
          edukietan akatsik egon ez dadin. Beraz, beretzat gordetzen du edonoiz
          horiek aldatzeko eskubidea. Tokikomek espresuki uko egiten dio webgune
          honetako edukietako akats edo hutsegiteengatiko erantzukizunari eta
          baita horien egiazkotasun-, zehaztasun- eta gaurkotasun-faltaren
          ondoriozko kalteengatiko erantzukizunari ere.Webgune honek beste
          webgune batzuetarako estekak jar ditzake. Tokikomek ez du inolako
          erantzukizunik bere gain hartzen estekatutako gune horiei dagokienez;
          izan ere, ez du horien gaineko kontrolik, ezta haien edukiaren
          eskainitako produktu eta zerbitzuen eta abarren gaineko kontrolik ere.
          Zerbitzu horien helburua, hain zuzen, erabiltzaileei beste
          informazio-iturri batzuen berri ematea da; horregatik, erabiltzailea
          soil-soilik bere erantzukizunpean sartuko da eduki horietara, bertan
          araututako baldintzen arabera. Sare sozialetarako esteken kasuan,
          Tokikomen edo hirugarrenen titulartasunekoak badira, beren
          erabilera–baldintzen arabera arautuko dira.
        </p>
        <p>
          Tratamenduaren Erantzulea ez da webgune honen edo bertako edukien
          erabileratik eratorritako edozein ondorio edo kalteren erantzulea
          izango, informatika-kalteak eta birusak txertatzea kontuan hartuta.
          Tokikomek ez du bermatzen webgunean ez dela inolako birusik edo
          bestelako elementurik izango, kanpoko hirugarrenek txertatutakoak, eta
          erabiltzaileen sistema fisiko zein logikoei kalteak ekar
          diezazkieketenak; nolanahi ere, eskura dituen bitarteko guztiak
          erabiliko ditu, horrelakorik gerta ez dadin.
        </p>
        <p>
          Tokikomek beretzat gordetzen du noiznahi, aldez aurretik oharrik edo
          azalpenik eman gabe, webgune honetako diseinua, konfigurazioa eta
          informazioa aldatzeko edo ezabatzeko eskubidea, baldin eta beharrezkoa
          dela iruditzen bazaio. Tokikomek ez du bere bizkar hartuko aldaketa
          horiek ekar ditzaketen kalteen erantzukizuna. Dena den, eskura dituen
          bitarteko guztiak erabiliko ditu, erabiltzaileei aldaketa horien berri
          emateko.
        </p>
        <h3>3. Jabetza intelektuala</h3>
        <p>
          Webgune honen jabetza-eskubideak, eta baita bertako orrialde, atalak,
          pantaila, informazio, itxura zein diseinu, produktu eta zerbitzu,
          iturburu-kodea, testuak, argazkiak, irudiak, soinua, audioak, bideoak,
          animazioak, kolore-konbinazioak, markak, logo, lelo eta gainerakoen
          jabetza-eskubideak ere, Tokikom enpresarenak dira, non eta ez den
          bestelakorik adierazten.
        </p>
        <p>
          Webgune honetan sartzeak edo bertan nabigatzeak ez du esan nahi, inola
          ere, Tokikomek bere jabetza intelektual edo industrialaren eskubideei
          uko egiten dienik, edo erabiltzaileari bere erabilera pertsonalerako
          utzi edo baimena (erabatekoa edo hein batekoa) ematen dionik.
          Debekatuta dago webgune honetako edukiak eta erabilerak
          erreproduzitzea, banatzea, merkaturatzea, publikoki komunikatzea,
          eraldatzea edo egin daitekeen beste edozein jarduera, Tokikomek aldez
          aurretik, berariaz eta idatziz baimena eman ezean.
        </p>
        <p>
          Erabiltzaileak webgune honen titulartasunari kalteak eragingo
          lizkiokeen zeinahi ekintza ez egiteko konpromisoa hartuko du.
          Webguneko informazioa baimenik gabe erabiliz gero, edo titularren
          jabetza intelektual eta industrialaren eskubideei kalteak eraginez
          gero, dagokien lege-ekintzak egikaritu ahal izango dira, eta hala
          badagokio, hori egikaritzetik eratorritako erantzukizunak bete beharko
          dira, ekonomikoak barne.
        </p>
        <h3>4. Indarraldia eta aldaketak</h3>
        <p>
          Baldintza hauek guztiak aldatu ahal izango dira, baldin eta Tokikomek
          noizbait egokitzat jotzen badu; bai lege-aldaketei egokitzeko, bai
          bestelako hobekuntza batzuk egiteko. Aldaketa horiek indarrean egongo
          dira webgune honetan argitaratzen diren unetik aurrera. Web gunera
          sartzen den unean aurreikusitako arauek arautuko dute
          erabiltzaileekiko harremana. Hori dela eta, Tokikomek webgunera
          konektatzen zaren bakoitzean baldintza hauek berrikustea gomendatzen
          dizu.
        </p>
        <h3>5. Jurisdikzioa</h3>
        <p>
          Tokikom eta erabiltzailearen artean sortutako harremana, hain zuzen,
          aplikatzeko modukoa den legediari eta eskumena daukan jurisdikzioari
          buruz indarrean den araudian xedatutakoak arautuko du. Nolanahi ere,
          araudiak aurreikusten badu aldeek eskumen baten mende jarri behar
          dutela, Tokikometa erabiltzailea, dagokien beste zeinahi eskumeni
          espresuki uko eginda, Donostiako (Gipuzkoa) Epaitegi eta Auzitegien
          menpe jarriko dira.
        </p>
        <p>
          Erabiltzaileak ez baditu baldintza hauek betetzen edo webgunean
          azaltzen diren edukien okerreko erabilera egiten badu, Tokikomek
          Zuzenbideak ematen dizkion neurri zibilak eta penalak (ekonomikoak
          barne) hartuko ditu ez-betetze hauek zigortzeko.
        </p>
        <h2>Tokikomen Pribatutasun Politika eta Datu Pertsonalen Babesa</h2>
        <table
          data-tablestyle="MsoNormalTable"
          data-tablelook="0"
          aria-rowcount="6"
        >
          <tbody>
            <tr>
              <td colspan="2">
                <b>
                  <span>Datuen Babeserako Oinarrizko Informazioa</span>
                </b>
              </td>
            </tr>
            <tr>
              <td>
                <b>
                  <span>Erantzulea</span>
                </b>
              </td>
              <td>
                <span>Tokikom, S.L. (IFZ: B75069864), aurrerantzean, “</span>
                <b>
                  <span>Tokikom</span>
                </b>
                <span>”</span>
              </td>
            </tr>
            <tr>
              <td>
                <b>
                  <span>Helburuak</span>
                </b>
              </td>
              <td>
                <span>
                  Tokikom enpresako zerbitzuak helarazi eta kudeatzea.
                </span>
                <p></p>
                <p>
                  <span>
                    Interesdunekin harremana mantentzea, egindako kontsultei
                    erantzutea eta eskaerei erantzuna ematea.
                  </span>
                </p>
                <p>
                  <span>
                    Webgunearen erabilera eta sare sozialetako presentzia
                    kudeatzea eta aztertzea.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <b>
                  <span>Legitimizazioa</span>
                </b>
              </td>
              <td>
                <span>
                  Datuen tratamenduaren beharra (“kontratu-harremana” RGPD 6.1.b
                  art.)
                </span>
                <p></p>
                <p>
                  <span>
                    Tokikom enpresaren legezko interesa (RGPD 6.1.f art.)
                  </span>
                </p>
                <p>
                  <span>Interesdunaren adostasuna (RGPD 6.1.a art.)</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <b>
                  <span>Hartzaileak</span>
                </b>
              </td>
              <td>
                <span>
                  Legez edo estatutuz ezarritako edo baimendutako komunikazioak;
                  Ekimen batzuetan parte hartzeak edo/eta irabazle edo saridun
                  izateak, interesdunaren identifikazio-datuak eta irudia
                  argitaratzea dakar web gune eta sare sozialetan
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <b>
                  <span>Eskubideak</span>
                </b>
              </td>
              <td>
                <span>
                  Datuen babeserako zure eskubideak erabiltzeko helbide honetara
                  jo dezakezu: Agoitz Plaza, 1, 48015 Bilbao (Bizkaia), posta-
                  elektronikoa:
                </span>
                <a href="mailto:info@matx.eus">
                  <span>info@matx.eus</span>
                </a>
                <span> edo 946 62 02 39 telefonoan</span>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>1. Tratamenduaren Erantzulea</h3>
        <p>
          “Tratamenduaren Erantzulea” Tokikom, S.L. da, aurrerantzean “Tokikom”
          edo “enpresa”.   I.F.Z.: B75069864 Helbidea: Agoitz Plaza, 1
          Herria: 48015 Bilbao (Bizkaia) Helbide elektronikoa: info@matx.eus 
          Telefonoa: 946 62 02 39
        </p>
        <h3>2. Indarrean dagoen legeria</h3>
        <p>
          Tokikomek egiten dituen datu-tratamenduei aplikatu behar zaien
          oinarrizko araudia:
        </p>
        <ol>
          <li>
            2016/679 (EB) Erregelamendua, Europako Parlamentuaren eta
            Kontseiluarena, 2016ko apirilaren 27koa, datu pertsonalen
            tratamenduari dagokionez pertsona fisikoen babesari eta datu horien
            zirkulazio askeari buruzko arauak ezartzen dituena (“RGPD”). 
          </li>
          <li>
            Datu Pertsonalak Babesteko eta eskubide digitalak bermatzeko 3/2018
            Lege Organikoa (“LOPDGDD”). 
          </li>
          <li>Arau osagarriak eta garapen-arauak.</li>
        </ol>
        <h3>3. Webguneak eta sare sozialetako kanalak</h3>
        <p>
          Tokikomen webgunea www.matx.eus da eta enpresak sare sozialetan dituen
          kanal ofizial nagusiak hauek dira:
        </p>
        <ul>
          <li>
            Instagram: 
            <a href="https://www.instagram.com/matxapp/">
              https://www.instagram.com/matxapp/
            </a>
          </li>
          <li>
            Tiktok:
            <a href="https://www.tiktok.com/@matxapp2">
              https://www.tiktok.com/@matxapp2
            </a>
          </li>
        </ul>
        <p>
          Pribatutasun Politika honen ondorioetako, aurrerantzean Tokikom
          enpresaren webgune ofizialei eta enpresaren sare sozialetako kanal
          ofizialei “webgunea” deituko zaie, kontrakoa zehazten denean izan
          ezik.
        </p>
        <h3>4. Helburuak eta Legitimaziorako Oinarri Juridikoak</h3>
        <p>
          Datu pertsonalak babesteari buruz indarrean dagoen araudia betez,
          erabiltzaileei jakinarazten zaie datu pertsonalak Tokikomek webgune
          edo sare sozialen bitartez (posta elektroniko bidez, inprimakien
          bidez, etab.) edo horien erabileraren ondorioz
          sortutakoak, Tokikomen tratamendu sistemetan sartu ahal izango direla.
        </p>
        <p>
          Tokikom enpresan webgunearen bidez egin daitezkeen datu-tratamenduen
          helburuak honako hauek dira:
        </p>
        <ol>
          <li>
            Tokikom enpresako zerbitzuak helarazi eta kudeatzea.
            Legitimazio-oinarri nagusia: Datuen tratamenduaren beharra
            (“kontratu- harremana” RGPD 6.1.b artikuluaren arabera)
          </li>
          <li>
            Interesdunekin harremana mantentzea, egindako kontsultei eta
            eskaerei erantzuna ematea. Legitimazio-oinarri nagusia:
            Interesdunaren adostasuna (RGPD 6.1.a art.)
          </li>
          <li>
            Gure webgunearen eta sare sozialetako presentziaren erabilera
            kudeatzea eta aztertzea; pertsona interesdunei informazio
            komertziala bidaltzea; ekitaldietan parte hartzen duten pertsonen
            irudia tratatzea. Legitimazio-oinarri nagusia: Tokikomen legezko
            interesa (RGPD 6.1.f art.)
          </li>
        </ol>
        <h3>5. Sekretu eginbeharra</h3>
        <p>
          Tokikom erabiltzaileen pribatutasunarekin eta datuen babesarekin
          konprometituta dago eta hauen tratamendua isilpean eta araudia betez
          egingo da. Tokikomeko langileei berariaz jakinarazi zaie sekretu
          profesionala gorde behar dutela.
        </p>
        <h3>6. Datu pertsonalak biltzea</h3>
        <p>
          Tokikomek ez dio erabiltzaileari datu pertsonalak emateko eskatzen
          bere webgunean sartzeko; alabaina, hainbat eginkizun aurrera eramateko
          ezinbestekoa izango da erabiltzaileak datu pertsonalak ematea
          (adibidez harremanetarako formularioan), batez ere identifikatzeko eta
          harremanetarako datuak.
        </p>
        <p>
          Erabiltzaileak era askean eta borondatez emango ditu bere datuak.
          Tokikomek erabiltzaileak eskatutako zerbitzua jasotzeko beharrezkoa
          den informazio pertsonala baino ez du eskatuko.
        </p>
        <p>
          Webgune honetan edozein eratako edukiak (datuak, testuak, bideoak,
          soinua, argazkiak, grafikoak, mezuak edo bestelako materiala)
          txertatzen dituen erabiltzaileak adierazi eta bermatu behar du hemen
          adierazitako baldintzen eta indarrean dagoen legediaren arabera egingo
          duela. Hirugarrenen datu pertsonalak gehitzen dituen erabiltzaileak
          adierazi behar du pertsona horien baimena daukala datuak
          argitaratzeko. Debekatuta dago hirugarrenen datuak sartzea haien
          berariazko baimenik gabe.
        </p>
        <p>
          Erabiltzailearen ardura eta beharra izango da emandako datuen
          zehaztasuna, zuzentasuna eta egiazkotasuna bermatzea eta, behar den
          kasuetan, datu hauek eguneratzea Tokikomek ez du honen inguruko
          erantzukizunik hartuko bere gainean. Erabiltzailea bera izango da,
          horrenbestez, ematen dituen datuekin eragindako kalteen erantzule
          bakarra. Webguneko formularioetan nahitaezkotzat jotako eremuak ez
          betetzearen edo oker betetzearen ondorioz, Tratamenduaren Erantzuleak
          ezin izango dio erantzun erabiltzailearen eskaerari.
        </p>
        <p>
          Tratamenduaren Erantzuleak datu pertsonalak modu egokian jasoko ditu,
          bere webgunean jasotako helburuen, erabileren, zerbitzuen eta/edo
          prestazioen arabera, modu zehatzean, esplizituan eta legitimoan;
          beraz, ez dira inola ere erabiliko iruzurrezko bitartekoak,
          bidegabeak, legez kontrakoak edo erabiltzaileen eskubide legitimoak
          arriskuan jartzen dituztenak.
        </p>
        <p>
          Webgune honen bitartez informazioa bidali behar bada, ezinbestekoa da
          honako pribatutasun politika hau, cookie politika eta webgunearen
          baldintza orokorrak espresuki onartzea.
        </p>
        <h3>7. Adin txikikoen datuak</h3>
        <p>
          Zenbait jarduera adin txikikoei zuzenduta egon daitezke. Kasu hauetan
          Tokikomek adin txikikoaren guraso edo tutore legalen baimena eskatuko
          du, datu pertsonalen bilketa, tratamendu eta, bere kasuan, datuen
          kokunikaziorako.
        </p>
        <p>
          Tokikomek, kontrol-agintaritzek haurren eta gazteen datu pertsonalak
          babesteko garatu dituzten materialak kontsultatzeko gomendatze du. 
          Informazio hori (gomendioak, teknologia berriak erabiltzeko
          eskuliburuak, hezkuntza-bideoak, etab.)
          <a href="https://www.avpd.euskadi.eus/webavpd00-content/es/contenidos/informacion/publicaciones_avpd/es_def/index.shtml">
            Datuak Babesteko Euskal Agentzian eskuragarri dago
          </a>
          , baita Datuak Babesteko Espainiako Agentzian ere.
        </p>
        <h3>8. Erregistroak eta harpidetzak</h3>
        <p>
          Informazio-buletinen edo antzeko zerbitzuen harpidetzak datu
          pertsonalen tratamendua eskatzen duenean, erabiltzaileek zerbitzu
          horien baja eskatu ahal izango dute, hala nahi duten
          unean, info@matx.eus helbidera eskaera bat bidaliz, edo posta
          elektronikoan azaltzen den “Baja eman” edo “Unsubscribe” mekanismoa
          erabiliz.
        </p>
        <h3>9. Enplegu-eskatzaileak</h3>
        <p>
          Tokikomek webgunean daukan baliabidearen bitartez, posta arruntez,
          formulario, porta elektroniko, sare sozial edo beste baliabidearen
          bitartez curriculuma bidaltzen duen pertsonari jakinarazten zaio, datu
          pertsonalak babesteari buruzko araudian ezarritakoaren arabera,
          enplegu-eskaeraren bidez emandako datuak Tratamenduaren Erantzuleraren
          tratamendu sistemetan sartuko direla.
        </p>
        <p>
          Zure datuak Tratamenduaren Erantzuleak burutu ditzaken langile
          hautaketa- prozesuetarako erabiliko dira. Era berean, zure
          datuak Tokikomekhautaketa prozesua egiteko kontratatu dezakeen
          hirugarren enpresa bati komunikatuak izan daitezke.
        </p>
        <p>
          Tokikomek curriculuma gordeko du urtebetez, gutxi gorabehera. Behin
          epe hori amaituta, datuak suntsitu egingo dira, konfideltzialtasuna
          bermatzeko behar diren sagurtasun-neurriak aplikatuz.
        </p>
        <p>
          Deskribatutako helburuaren batekin ados ez bazaude, mesedez, jakinaraz
          iezaguzu idatziz lehenbailehen. Era berean, zure datuetan aldaketarik
          izanez gero, jakinarazi, mesedez. Zure datu pertsonalak babesteko
          eskubideak erabil ditzakezu (datuak ezabatzea, eskuratzea, zuzentzea,
          tratamendua mugatzea, tratamenduari aurka egitea, etab.), bai eta gai
          honi buruzko edozein eskaera ere, pribatasun-politika honetako
          “Interesdunen Eskubideak” atalean adierazitakoaren arabera.
        </p>
        <h3>10. Sare sozialetarako eta web kanaletarako estekak</h3>
        <p>
          Webgune honek sare sozialetarako eta kanpoko webgune eta web-
          kanaletarako estekak eskaini ditzake. Tokikomek ez du bere gain
          hartzen estekatutako gune horien gaineko inongo erantzukizunik.
          Webgune honetan kokatutako esteketatik edo beste edozein
          modutan Tokikomrekinzerikusia duten sare sozialetan eta kanpoko
          webgune eta web-kanaletan sartzen den erabiltzaileari aldez aurretik
          eta berariaz jakinarazten diogu erabilera-baldintzak,
          pribatutasun-politikak, cookien politikak eta beste edozein baldintza
          eta/edo araudi sare sozial eta webgune eta web-kanal horien
          arduradunen menpe daudela, inoiz ez Tokikom enpresaren menpe.
        </p>
        <h3>11. Tokikom enpresaren presentzia Sare Sozialetan</h3>
        <p>
          Tokikomek Sare Sozial desberdinetan profilak ditu, elkartearekin edo
          haren jarduerarekin lotutako produktuak, zerbitzuak eta jarduerak
          zabaltzeko helburu nagusiarekin. Kanal horien bidez eskaintzen diren
          argitalpenak orientagarriak izango dira.
        </p>
        <p>
          Erabiltzaileak bere sare sozialetan duen profilarekin Tokikom
          enpresaren profiletan erregistratzean, bereriazko baimena ematen dio
          elkarteari bere datu pertsonalak Pribatutasun Politika honen arabera
          tratatzeko.
        </p>
        <p>
          Erabiltzaileak sare sozialen bidez borondatez emandako informazioa eta
          datu pertsonalak Tokikomek erabiltzailearekin harremanetan jartzeko,
          erabiltzaileak egiten dituen kontsultei eta eskaerei erantzuteko,
          elkartearekin edo haren jarduerarekin lotutako informazio eta
          komunikazioak bidaltzeko eta lehiaketak, zozketak, gonbidapenak eta
          antzeko jarduerak kudeatzeko erabil daitezke. Era berean,
          erabiltzaileak Tokikom enpresari eta haren argitalpenei buruz duen
          iritzia edo balorazioa ezagutzeko eta, oro har, Tokikomek sare
          sozialetan duen presentzia kudeatzeko erabiliko da.
        </p>
        <p>
          Erabiltzaileei jakinarazten zaie Tokikom enpresaren sare sozialen
          bidez argitaratzen den informazioa eta edukiak (datuak, testuak,
          bideoak, soinua, argazkiak, grafikoak, iruzkinak, mezuak edo beste
          material bat) sare sozialen beste erabiltzaile batzuen esku gera
          daitezkeela (hala badagokio, datu pertsonalak barne). Hirugarrenen
          datu pertsonalak erabiltzen dituen erabiltzaileak nahitaez eskatu
          behar du datu horiek tratatzeko baimena. Sare sozial bakoitzaren
          erabiltzaile-profila konfiguratzen denetik, erabiltzaileak kasu
          bakoitzean publiko egin nahi duen informazioa konfiguratu dezake, eman
          dituen baimenak ikusi, ezabatu edo desaktibatu, eta bere argitalpenak
          kudeatu, bere irizpidearen eta sare sozial bakoitza erabiltzeko arauen
          arabera.
        </p>
        <p>
          Erabiltzailearen argitalpenek indarrean dagoen legedia, sare sozialek
          ezarritako pribatutasun politikak eta erabilera arauak eta Tokikomek
          duen pribatutasun politika bete beharko ditu. Honen erantzule bakarra
          erabiltzailea izango da. Tokikomek ez du inolako erantzukizunik
          hartzen bere gain erabiltzaile bakoitzak egiten dituen argitalpenei
          dagokienez. Tokikomek alde bakarrez eta aldez aurretik jakinarazi gabe
          ezabatuko ditu indarrean dagoen legedia, erabilera arauak eta
          ezarritako pribatutasun politikak urratzen dituzten edukiak, edo
          desegokitzat jotzen dituenak.
        </p>
        <p>
          Tokikomek Facebook, Twittter, Flikr, Youtube eta beste sare sozial
          batzuetako profilak jarraitzen ditu, baina horrek ez du esan nahi
          horiekin inolako loturarik dagoenik.
        </p>
        <p>
          Era berean, sare sozial edo profesionaletako Tokikom
          enpresaren kanalen jarraipena sare horien beste erabiltzaile batzuek
          egin dezakete, edo haien argitalpenak hirugarrenek birzabaldu, baina
          horrek ez du esan nahi haiekin inolako loturarik dagoenik.
        </p>
        <p>
          Tokikomek beretzat gordetzen du bide honen bitartez jasotako mezu edo
          iruzkinei ez erantzuteko eskubidea. Tokikomek edozein unetan eta aldez
          aurretik abisatu gabe aldatu edo ezabatu ahal izango ditu bere sare
          sozialetan igorritako argitalpenak.
        </p>
        <p>
          Jarraitzailea izatean edo sare sozialetan “gustatzen zait” sakatzean,
          onartzen duzu: 1) zure datu pertsonalak dagokion sare sozialaren
          pribatutasun politikaren inguruan tratatzea; 2) Tokikomek zure
          kontaktu zerrendan jasotako datuak eskuratzea; 3) elkarteak
          argitaratutako albisteak eta promozioak zure abisu eta
          jakinarazpenetan agertzea.
        </p>
        <p>
          Erabiltzaileak Tokikom enpresaren sare sozialetako kanal ofizialak
          jarraitzeari utzi nahi badio, sare sozial bakoitzak horretarako
          ezarritako tresnak erabiliz egin behar du.
        </p>
        <h3>12. Segurtasun Neurriak</h3>
        <p>
          Tokikomek, bere tratamendu-sistemetan dauden datu pertsonalak
          erabiltzean, datuen segurtasuna bermatzeko eta horiek aldatzea,
          galtzea edo baimenik gabe erabiltzea saihesteko beharrezkoak diren
          neurri tekniko eta antolamendu neurriak hartuko ditu. Betiere,
          teknologia-egoera, bildutako datuen izaera eta horiek izan ditzaketen
          arriskuak kontuan izanda; bai giza jardunak eragindakoak, bai ingurune
          fisiko zein naturalak eragindakoak. Horretarako, aintzat hartuko du
          datu pertsonalak babesteko indarrean den legedia. Nolanahi ere,
          erabiltzaileei argi adieraziko zaie segurtasun-neurri informatikoak ez
          direla erasoezinak, eta ondorioz, gerta litezkeela legez kanpoko
          esku-hartze edo esku-hartze bidegabeak, baina ez liratekeela Tokikom
          enpresaren erantzukizunpekoak.
        </p>
        <h3>13. Segurtasun urraketa</h3>
        <p>
          Erabiltzaileak ahalik eta azkarren jakinarazi beharko dio
          Tratamenduaren Erantzuleari edozein intzidentzia, segurtasun urraketa
          edo ez beharrezko sarbidea egon dela detektatuz gero. Jakinarazpena
          egiteko info@matx.eus helbide elektronikoa erabili ditzake.
        </p>
        <h3>14. Kontserbazio epea</h3>
        <p>
          Emandako datu pertsonalak, hauek bildu diren helburuak betetzeko,
          helburuak ekar ditzaketen erantzukizunak zehazteko eta kasu bakoitzean
          aplikatu beharreko araudiak (kontabillitatekoa, fiskala,
          administratiboa, etab.) ezartzen dituen aldian gordeko dira.
        </p>
        <h3>15. Interesdunen eskubideak</h3>
        <p>
          Zure datuak ikustea, zuzentzea, ezereztea, tratamendua mugatzea,
          tratamenduari aurka egitea eta portabilitatea eska dezakezu, baita
          zure baimena ezeztatu eta gai honi buruzko bestelako edozein eskaera
          egin ere. Horretarako, idatzi bat bidali beharko diozu Tratamenduaren
          Erantzuleari, helbide honetara: Agoitz Plaza, 1 – 48015 Bilbao
          (Bizkaia), info@matx.eus helbide elektronikoaren bitartez.
        </p>
        <p>
          Eskaerarekin batera, interesdunaren nortasun agiria baten kopia bidali
          beharko da. Eskaera lege-ordezkari moduan egiten baduzu, berriz,
          nortasun agiri baten kopia ez ezik, ordezkaritza horren egiaztagiria
          ere bidali beharko da.  Eskariari emandako erabakiarekin ados ez
          bazaude edo erreklamazio bat jarri nahi baduzu, kontrol aginte
          eskudunera jo dezakezu, kasu honetan, Datuak Babesteko Bulegoa
          (www.aepd.es).
        </p>
        <h3>16. Indarraldia eta aldaketak</h3>
        <p>
          Tokikomek beretzat gordetzen du bere pribatutasun-politika aldatzeko
          eskubidea, bai ordura arteko irizpideak aldatu dituelako, bai lege-
          edo jurisprudentzia-aldaketak izan direlako. Tokikomek aldaketaren bat
          eginez gero, ordea, testu berria webgune berean argitaratuko du, eta
          erabiltzailea berehala ohartuko da. Dena den, erabiltzailea webgunean
          sartzen den unean bertan aurreikusita dauden arauek arautuko dute
          erabiltzaileekiko harremana. Horregatik, Tokikomek gomendatzen dizu
          aldian-aldian sartzeko pribatutasun- politika honetara.
        </p>
        <h3>17. Jurisdikzioa</h3>
        <p>
          Tokikom eta erabiltzailearen artean sortutako harremana, hain zuzen,
          aplikatzeko modukoa den legediari eta eskumena daukan jurisdikzioari
          buruz indarrean den araudian xedatutakoak arautuko du. Nolanahi ere,
          araudiak aurreikusten badu aldeek eskumen baten menpe jarri behar
          dutela, Tokikom eta erabiltzailea, dagokien beste zeinahi eskumeni
          espresuki uko eginda, Donostiako (Gipuzkoa) Epaitegi eta Auzitegien
          menpe jarriko dira.
        </p>
        <p>
          Erabiltzaileak ez baditu baldintza hauek betetzen edo webgunean
          azaltzen diren edukien okerreko erabilera egiten badu Tokikomek
          Zuzenbideak ematen dizkion neurri zibilak eta penalak (ekonomikoak
          barne) hartuko ditu ez-betetze hauek zigortzeko.
        </p>
        <h2>Cookie politika</h2>
        <p>
          <strong>Zer dira cookieak?</strong>
        </p>
        <p>
          Cookie bat datu fitxategi txiki bat da, zure ordenagailu, sakelako
          telefono edo bestelako gailu batera bidali eta zure nabigatzailean
          gordeta geratzen dena gure webgunea bisitatzen duzunean. Cookieei
          esker, zure nabigazioaren inguruko informazioa jasotzen dugu eta zu
          zalantzarik barik identifikatzeko aukera ematen du. Zuk aukeratu
          cookieak onartzen dituzun edo ez, baina ez onartzea erabakitzen
          baduzu, blokeatu egin behar dituzu zure nabigatzailean.
        </p>
        <p>
          <strong>Cookie motak eta helburuak.</strong>
        </p>
        <p>
          Webgune honetatik nabigatzerakoan, saioaren cookieak eta cookie
          iraunkorrak erabiltzen dira, eta, aldi berean, cookie propioak edo
          hirugarren batzuenak izan daitezke.
        </p>
        <p>
          Saioaren cookieak erabiltzailea web orri batera sartzen denean datuak
          jaso eta gordetzeko diseinatutako cookieak dira. Erabiltzaileak behin
          bakarrik eskatu duen zerbitzu bat eskaintzeko bakarrik eman den
          informazio biltzeko erabiltzen dira.
        </p>
        <p>
          Cookie iraunkorren bidez ostera, datuek terminalean gordeta jarraitzen
          dute, eta cookie horren arduradunak horiek ikusi eta erabil ditzake,
          epe batean.. Epe hori minutu batzuen eta urte batzuen bitartekoa izan
          daiteke.
        </p>
        <p>
          <strong>COOKIE PROPIOAK</strong>: TOKIKOMek berak sortu edo
          kudeatutakoak.{' '}
          <strong>
            <em>tokikom.eus</em>
          </strong>{' '}
          webguneak cookie propio iraunkorrak erabiltzen ditu helburu hauekin:
        </p>
        <ul>
          <li>
            <strong>Pertsonalizazioa</strong>: Zerbitzura sartzeko modua ematen
            du, aurretik zehaztutako ezaugarri orokor batzuk ezarrita; adibidez,
            webgunea erakusteko aukeratu duzun hizkuntza.
          </li>
          <li>
            <strong>Publizitatea</strong>: Webgunearen bidez erakusten dugun
            publizitatea kudeatzeko.
          </li>
          <li>
            <strong>Jokaaeraren araberako publizitatea</strong>: Webgunearen
            sartzen ditugun publizitate-eremuak ahalik eta modurik
            eraginkorrenean kudeatzeko aukera ematen dute. Cookie hauek
            erabiltzaileen portaerari buruzko informazioa biltzen dute, horien
            nabigatzeko ohiturak etengabe behatuta, eta horrek profil
            espezifikoa garatzeko aukera ematen du, publizitatea horren arabera
            erakusteko.
          </li>
          <li>
            <strong>Teknikak</strong>: Saioan zu identifikatu eta horrela
            webgunera egingo dituzun hurrengo bisitetan zu ezagutzeko.
          </li>
        </ul>
        <p>
          <strong>HIRUGARRENEN COOKIEAK</strong>: Zerbitzu analitikoa,
          publizitatea eta jokaeraren araberako publizitatea ematen dutenak
          administratzen dituztenak, TOKIKOMetik kanpo. Halaber, edukia sare
          sozialetan partekatzeko erabiltzen dugu (plugina). Web orriaren edukia
          nola partekatzen den jakiteko informazio biltzeko ere erabiltzen dira
          cookie hauek.
        </p>
        <p>
          Segurtasun kontuetarako ere erabiltzen ditugu cookieak, zerbitzarietan
          gehiegikeriak identifikatzeko.
        </p>
        <p>
          Zure ekipoko cookieak (informatu eta baimena jasota) konfiguratu eta
          ikus ditzaketen hirugarrenak ikusteko, begiratu mesedez
          <a href="https://tokikom.eus/cookie-policy-eu/">
            orrialde honen 6. puntua
          </a>
          .
        </p>
        <p>
          <strong>Cookieak blokeatzea</strong>
        </p>
        <p>
          Kontuan izan behar duzu
          <a
            href="https://www.tokikom.eus"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.tokikom.eus
          </a>
          web orriko edukien ezaugarri batzuk bakarrik izango direla erabilgarri
          zure nabigatzailean cookieak instalatzeko baimena ematen baduzu.
          Cookieak (horien funtzioaren arabera) blokeatzea erabakitzen baduzu,
          baliteke www.tokikom.eus webaren ezaugarrietako batzuk behar bezala ez
          funtzionatzea edo horren zerbitzu batzuetarako sarbidea ez izatea.
        </p>
        <p>
          <strong>Nola desgaitu edo blokeatu cookieak?</strong>
        </p>
        <p>
          Nabigatzaile gehienek cookieak onartzeko konfigurazioa dute
          lehenetsita, baina, nahi izanez gero, konfigurazioa alda dezakezu
          cookie batzuk edo guztiak desgaitzeko. Zure nabigazio-esperientziari
          nola eragiten dion gustatzen ez bazaizu, berriz alda dezakezu.
        </p>
        <p>
          <strong>Eguneraketak eta aldaketak Cookien Politikan.</strong>
        </p>
        <p>
          TOKIKOMek aldaketak egin ditzake cookie politika honetan, lege edo
          araudi berriek eskatuta, edota politika hori Datuak Babesteko
          Espainiako Agentziak emandako aginduetara egokitzeko.
        </p>
        <p>
          Cookie Politika honetan aldaketa nabarmenik egiten denean,
          erabiltzaileari jakinaraziko zaio (editorearen web orrian oharra
          kaleratuz edo erregistratuta dauden erabiltzaileei mezu elektronikoa
          bidaliz horren berri emateko).
        </p>
        <p>
          Cookieen erabilerari buruzko informazio gehiago nahi baduzu, mezu
          elektronikoa bidali <strong>info@tokikom.eus</strong> helbidera.
        </p>
      </div>
    </div>
  );
};

export default Legeoharra;

import {
  Home,
  News,
  Clubs,
  Club,
  Lives,
  Live,
  App,
  StaticPage,
  Legeoharra,
  Harremanetarako,
  NewItem,
} from '../pages';

export const INDEX_COMPONENT = Home;
const ROUTES = {
  NEWS: {
    path: 'albisteak',
    Component: News,
  },
  NEWITEM: {
    path: 'albisteak/:type/:newid',
    Component: NewItem,
  },
  CLUBS: {
    path: 'klubak',
    Component: Clubs,
  },
  CLUB: {
    path: 'klubak/:clubid',
    Component: Club,
  },
  LIVES: {
    path: 'zuzenean',
    Component: Lives,
  },
  LIVE: {
    path: 'zuzenean/:liveid',
    Component: Live,
  },
  APP: {
    path: 'app',
    Component: App,
  },
  ABOUT_US: {
    path: 'honi-buruz',
    Component: () => StaticPage('honi_buruz_load', 'Honi buruz'),
  },
  LEGEOHARRA: {
    path: 'lege-oharrak',
    Component: Legeoharra,
  },
  HARREMANETARAKO: {
    path: 'harremanetarako',
    Component: Harremanetarako,
  },
  DOWNLOAD_MATX: {
    path: 'jaitsi-matx',
    Component: () =>
      StaticPage('jaitsi_matx_aplikazioa_load', 'Jaitsi Matx Aplikazioa'),
  },
};

export default ROUTES;

import React, { useEffect } from 'react';
import b_appstore from '../../theme/img/content-images/b_appstore.png';
import b_Gplay from '../../theme/img/content-images/b_Gplay.png';
import { useAsync } from '../../hooks';
import { getJaitsiMatx } from '../../api/matx';
import { Modal as ModalB } from 'react-bootstrap';
import { APP_READY } from '../../config/constants';

const Modal = ({ show, setShowModal }) => {
  const {
    triggerFunction: triggerFunctionJM,
    data: dataJM,
    loaded: loadedJM,
  } = useAsync();
  useEffect(() => {
    triggerFunctionJM(getJaitsiMatx);
  }, [triggerFunctionJM]);
  return (
    <ModalB show={show} onHide={() => setShowModal(false)}>
      <ModalB.Header closeButton></ModalB.Header>
      {APP_READY ? (
        <ModalB.Body>
          <h4>Eduki hau aplikazioan dago eskuragarri</h4>
          <h2>Deskargatu APP-a !</h2>
          {loadedJM && (
            <div className="estekak">
              <a href={dataJM.google_play?.download_url}>
                <img
                  alt="Deskargatu Matx aplikazioa Google play bidez"
                  src={b_Gplay}
                />
              </a>
              <a href={dataJM.app_store?.download_url}>
                <img
                  alt="Deskargatu Matx aplikazioa App store bidez"
                  src={b_appstore}
                />
              </a>
            </div>
          )}
        </ModalB.Body>
      ) : (
        <ModalB.Body>
          <h2>Matx APPa laster eskuragarri!</h2>
          <h4>Eduki hau aplikazioan egongo da</h4>
        </ModalB.Body>
      )}
    </ModalB>
  );
};

export default Modal;

import i_handball from '../theme/img/i_handball.svg';
import i_basket from '../theme/img/i_basket.svg';
import i_football from '../theme/img/i_football.svg';

export const DOMAIN_PATH = 'https://klubak.matx.eus';
export const API_PATH = `${DOMAIN_PATH}/matx_rest_api`;
export const DB_KEYS = {
  LANGUAGE: 'language',
};

export const ARTICLE_TYPES = { m: 'matx_erredakzioa', d: 'drupal' };
export const SPORTS = {
  futbol: {
    icon: i_football,
    title: 'Futbola',
  },
  saskibaloia: {
    icon: i_basket,
    title: 'Saskibaloia',
  },
  eskubaloia: {
    icon: i_handball,
    title: 'Eskubaloia',
  },
};

export const TRACKING_ID = 'G-YRWJBL2KQ0';

export const APP_READY = true;
export const ZUZENEKOAK_READY = false;

export const WEEKDAY = [
  'Igandea',
  'Astelehena',
  'Asteartea',
  'Asteazkena',
  'Osteguna',
  'Ostirala',
  'Larunbata',
];

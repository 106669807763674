import React from 'react';
import verticalPlaceholder from '../../theme/img/content-images/verticalPlaceholder.jpg';
import { ROUTES } from '../../config';
const TeaserCard = ({ item, setShowModal = (bool) => {} }) => {
  return (
    <div className="teaser-card">
      {item.media_embed_kodeak.length > 0 &&
      item.media_embed_kodeak[0].poster_url ? (
        <a
          href={`/${ROUTES.NEWS.path}/${item.article_type.slice(0, 1)}/${
            item.nid
          }`}
        >
          <img alt={item.title} src={item.media_embed_kodeak[0].poster_url} />
        </a>
      ) : item.get_photos?.length > 0 ? (
        <a
          href={`/${ROUTES.NEWS.path}/${item.article_type.slice(0, 1)}/${
            item.nid
          }`}
        >
          <img alt={item.title} src={item.get_photos[0].image} />
        </a>
      ) : (
        <img alt={item.title} src={verticalPlaceholder} />
      )}
      <div>
        <span className="teaser-data">{item.public_date.slice(0, 10)}</span>
        <span className="author">Matx Erredakzioa</span>
      </div>
      <a
        href={`/${ROUTES.NEWS.path}/${item.article_type.slice(0, 1)}/${
          item.nid
        }`}
      >
        <h4 className="teaser-title">{item.title}</h4>
      </a>
    </div>
  );
};
export default TeaserCard;

import { ARTICLE_TYPES } from '../config/constants';
import api from './api';

export const getArtikuluak = (page) => {
  return page ? api.get(`/artikuluak?page=${page}`) : api.get(`/artikuluak`);
};

export const getAlbistea = (clubid, type = 'matx_erredakzioa') => {
  if (ARTICLE_TYPES[type]) {
    return api.get(`/artikuluak/${clubid}/${ARTICLE_TYPES[type]}`);
  } else {
    return api.get(`/artikuluak/${clubid}/${type}`);
  }
};

export const getErredakziokoak = (page) => {
  return page
    ? api.get(`/artikuluak/matx_erredakziokoak?page=${page}`)
    : api.get(`/artikuluak/matx_erredakziokoak`);
};

export const getAplikaziokoak = (page) => {
  return page
    ? api.get(`/artikuluak/aplikaziotik?page=${page}`)
    : api.get(`/artikuluak/aplikaziotik`);
};

export const getHedabideetakoak = () => {
  return api.get('/artikuluak/hedabidetakoak');
};

export const getKlubarenAlbisteak = (club, page) => {
  return page
    ? api.get(`/klubak/${club}/artikuluak?page=${page}`)
    : api.get(`/klubak/${club}/artikuluak`);
};

export const getKlubak = (page) => {
  return page ? api.get(`/klubak?page=${page}`) : api.get(`/klubak`);
};

export const getKluba = (clubid) => {
  return api.get(`/klubak/${clubid}`);
};

export const getZuzenekoak = (page) => {
  return page ? api.get(`/zuzenekoak?page=${page}`) : api.get(`/zuzenekoak`);
};

export const getZuzenekoa = (liveid) => {
  return api.get(`/zuzenekoak/${liveid}`);
};

export const getStaticPage = (route) => {
  return api.get(`/${route}`);
};

export const getJaitsiMatx = () => {
  return api.get('/jaitsi_matx_aplikazioa_load');
};

export const postHarremanetarako = (data) => {
  return api.post('/harremanetarako/bidali', data);
};

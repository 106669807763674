import React from 'react';

const Loader = () => {
  return (
    <div className="text-center">
      <div
        className="spinner-border"
        style={{ width: '4rem', height: '4rem' }}
        role="status"
      >
        <span className="visually-hidden">Kargatzen...</span>
      </div>
    </div>
  );
};

export default Loader;

import React from 'react';
import horizontalPlaceholder from '../../theme/img/content-images/horizontalPlaceholder.jpg';

const NewsCard = ({ item }) => {
  return (
    <div className="card-news">
      {item.photo ? (
        <picture className="">
          <img alt={item.title} src={item.photo} />
        </picture>
      ) : (
        <picture className="">
          <img
            alt="Albistearentzat esanahi gabeko defektuzko irudi bat"
            src={horizontalPlaceholder}
          />
        </picture>
      )}
      <div>
        <span className="news-data">{item.public_date.slice(0, 10)}</span>
        {item.hedabidea && (
          <span className="author">{item.hedabidea.name}</span>
        )}
      </div>

      <h5 className="news-title">
        <a target="_blank" href={item.url} rel="noreferrer">
          {item.title}
        </a>
      </h5>
    </div>
  );
};

export default NewsCard;

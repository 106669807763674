import React, { useEffect } from 'react';
import imageapp from '../theme/img/content-images/image-app.png';
import { useAsync } from '../hooks';
import { getJaitsiMatx } from '../api/matx';
import b_appstore from '../theme/img/content-images/b_appstore.png';
import b_Gplay from '../theme/img/content-images/b_Gplay.png';
import { APP_READY } from '../config/constants';
const App = () => {
  const {
    triggerFunction: triggerFunctionJM,
    data: dataJM,
    loaded: loadedJM,
  } = useAsync();
  useEffect(() => {
    triggerFunctionJM(getJaitsiMatx);
  }, [triggerFunctionJM]);
  return (
    <div className="page">
      <h1 className="documentFirstHeading">App-a</h1>
      <div id="column-content">
        <section className="why">
          <img
            alt="Matx aplikazioaren mugikorreko irudia"
            src={imageapp}
            className="promo-image"
          />
          <h2 className="mb-3">Tokian tokiko Kirol komunitate digitala</h2>
          <p className="h5">
            Gertuko kirolaren inguruko aktualitatea jarraitzeko leihoa.
          </p>
          <p className="h5">
            Kirol kluben kudeaketarako tresna eta euskarazkoa kirol komunitatea
          </p>
          <h3 className="mt-5 mb-3">Zergatik MATX?</h3>
          <p className="h5">Gertukoa, euskaraz eta multiplataforma delako.</p>
        </section>
        <section className="promo-appa mt-lg-5 mt-3">
          {APP_READY ? (
            <h2>Deskargatu MATX APP-a!</h2>
          ) : (
            <h2>Matx APPa laster eskuragarri!</h2>
          )}
          {APP_READY && (
            <div className="estekak">
              {loadedJM && dataJM.google_play?.download_url && (
                <a href={dataJM.google_play?.download_url}>
                  <img
                    alt="Deskargatu Matx aplikazioa Google play bidez"
                    src={b_Gplay}
                  />
                </a>
              )}
              {}
              {loadedJM && dataJM.app_store?.download_url && (
                <a href={dataJM.app_store?.download_url}>
                  <img
                    alt="Deskargatu Matx aplikazioa App store bidez"
                    src={b_appstore}
                  />
                </a>
              )}
            </div>
          )}
        </section>
        {/* <section className="tutoriala mt-lg-5 mt-3">
          <h2 className="text-center">Bideo tutoriala</h2>
          <div className="video"><iframe></iframe></div>
        </section> */}
      </div>
    </div>
  );
};

export default App;

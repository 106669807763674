import { getAlbistea } from '../api/matx';
import { Loader } from '../components';
import { useAsync } from '../hooks';
import React, { useEffect } from 'react';
// import { FormattedMessage } from 'react-intl';
// import { ROUTES } from '../config';
import { useParams } from 'react-router-dom';

const NewItem = () => {
  const { triggerFunction, data, loading, loaded } = useAsync();
  let { type, newid } = useParams();

  useEffect(() => {
    triggerFunction(getAlbistea, newid, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFunction]);

  return (
    <div>
      <h1 className="documentFirstHeading">{loaded && data.title}</h1>
      {loading && !loaded && <Loader />}

      {data && (
        <div id="column-content" className="albistea-page">
          <p className="teaser-data">{data.public_date.slice(0, 10)}</p>
          <div className="media mb-3">
            {data.get_photos_embed_kodea ? (
              <iframe
                title="Argazki galeria"
                width="100%"
                height="500"
                allowFullScreen
                src={data.get_photos_embed_kodea}
              />
            ) : data.media_embed_kodeak.length > 0 ? (
              data.media_embed_kodeak.map((ek) => (
                <iframe
                  title="Media edukia"
                  width="100%"
                  height="500"
                  allowFullScreen
                  src={ek.embed_kodea}
                />
              ))
            ) : data.get_photos?.length > 0 ? (
              <img alt={data.title} src={data.get_photos[0].image} />
            ) : (
              <></>
            )}
          </div>
          <div>
            <div dangerouslySetInnerHTML={{ __html: data.get_body }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default NewItem;

import React from 'react';
import { NewsCard, TeaserCard } from '..';

const Card = ({ item, setShowModal = (bool) => {} }) => {
  const teasers = ['drupal', 'matx_erredakzioa'];
  return teasers.includes(item.article_type) ? (
    <TeaserCard item={item} setShowModal={setShowModal} />
  ) : (
    <NewsCard item={item} />
  );
};

export default Card;

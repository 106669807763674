import React from 'react';

const Pagination = ({
  page = 1,
  setPage,
  setSearchParams,
  page_size = 10,
  count,
  previous,
  next,
}) => {
  const num_of_pages = Math.ceil(count / page_size);
  const pages = Array.from({ length: num_of_pages }, (value, index) => index);

  return (
    <nav className="pagination" aria-label="Page navigation example">
      <ul>
        {previous && (
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions*/}
            <a
              href="#title"
              onClick={() => {
                setPage(page - 1);
                setSearchParams({ page: page - 1 });
              }}
              onKeyDown={() => {
                setPage(page - 1);
                setSearchParams({ page: page - 1 });
              }}
            >
              Aurreko {page_size} elementuak
            </a>
          </li>
        )}
        {num_of_pages > 1 && num_of_pages < 11
          ? pages.map((key) => (
              <li key={key} className={page === key + 1 ? 'active' : `${key}`}>
                {page === key + 1 ? (
                  <span>{key + 1}</span>
                ) : (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions
                  <a
                    href="#title"
                    onClick={() => {
                      setPage(key + 1);
                      setSearchParams({ page: key + 1 });
                    }}
                    onKeyDown={() => {
                      setPage(key + 1);
                      setSearchParams({ page: key + 1 });
                    }}
                  >
                    {key + 1}
                  </a>
                )}
              </li>
            ))
          : num_of_pages > 1 && (
              <>
                {pages
                  .slice(0, page === 3 ? 4 : page === 4 ? 1 : 3)
                  .map((key) => (
                    <li
                      key={key}
                      className={page === key + 1 ? 'active' : `${key}`}
                    >
                      {page === key + 1 ? (
                        <span>{key + 1}</span>
                      ) : (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions
                        <a
                          href="#title"
                          onClick={() => {
                            setPage(key + 1);
                            setSearchParams({ page: key + 1 });
                          }}
                          onKeyDown={() => {
                            setPage(key + 1);
                            setSearchParams({ page: key + 1 });
                          }}
                        >
                          {key + 1}
                        </a>
                      )}
                    </li>
                  ))}

                {!pages.slice(0, 3).includes(page - 1) &&
                !pages.slice(-3).includes(page - 1) ? (
                  <>
                    <li>
                      <span>...</span>
                    </li>
                    <li>
                      <a
                        href="#title"
                        onClick={() => {
                          setPage(page - 1);
                          setSearchParams({ page: page - 1 });
                        }}
                        onKeyDown={() => {
                          setPage(page - 1);
                          setSearchParams({ page: page - 1 });
                        }}
                      >
                        {page - 1}
                      </a>
                    </li>
                    <li className={'active'}>
                      <span>{page}</span>
                    </li>
                    <li>
                      <a
                        href="#title"
                        onClick={() => {
                          setPage(page + 1);
                          setSearchParams({ page: page + 1 });
                        }}
                        onKeyDown={() => {
                          setPage(page + 1);
                          setSearchParams({ page: page + 1 });
                        }}
                      >
                        {page + 1}
                      </a>
                    </li>
                    <li>
                      <span>...</span>
                    </li>
                  </>
                ) : (
                  <li>
                    <span>...</span>
                  </li>
                )}
                {pages
                  .slice(
                    num_of_pages - 2 === page
                      ? -4
                      : num_of_pages - 3 === page
                      ? -1
                      : -3,
                  )
                  .map((key) => (
                    <li
                      key={key}
                      className={page === key + 1 ? 'active' : `${key}`}
                    >
                      {page === key + 1 ? (
                        <span>{page}</span>
                      ) : (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions
                        <a
                          href="#title"
                          onClick={() => {
                            setPage(key + 1);
                            setSearchParams({ page: key + 1 });
                          }}
                          onKeyDown={() => {
                            setPage(key + 1);
                            setSearchParams({ page: key + 1 });
                          }}
                        >
                          {key + 1}
                        </a>
                      )}
                    </li>
                  ))}
              </>
            )}
        {next && (
          <li className="">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions*/}
            <a
              href="#title"
              className=""
              onClick={() => {
                setPage(page + 1);
                setSearchParams({ page: page + 1 });
              }}
              onKeyDown={() => {
                setPage(page + 1);
                setSearchParams({ page: page + 1 });
              }}
            >
              Hurrengo {page_size} elementuak
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;

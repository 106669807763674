import React, { useEffect, useState } from 'react';
// import { FormattedMessage } from 'react-intl';
// import { ROUTES } from '../config';
import { useParams, useSearchParams } from 'react-router-dom';

import { getKluba, getKlubarenAlbisteak } from '../api/matx';
import { useAsync } from '../hooks';
import { Pagination, Modal, Card } from '../components';

const Club = () => {
  const page_size = 10;
  let [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get('page') ? parseInt(searchParams.get('page')) : 1,
  );
  const { triggerFunction, data, loading, loaded } = useAsync();
  const {
    triggerFunction: triggerFunctionKA,
    data: dataKA,
    loaded: loadedKA,
  } = useAsync();
  let { clubid } = useParams();

  useEffect(() => {
    triggerFunction(getKluba, clubid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFunction]);

  useEffect(() => {
    triggerFunctionKA(getKlubarenAlbisteak, clubid, page);
    return () => {};
  }, [triggerFunctionKA, clubid, page]);
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Modal show={showModal} setShowModal={setShowModal} />
      <h1 className="documentFirstHeading">
        {loaded && (
          <>
            <img
              src={data?.logo?.url || 'https://via.placeholder.com/200x200.jpg'}
              alt={data.detalleclub}
            />
            {data.detalleclub}
          </>
        )}
      </h1>
      {loading && !loaded && <p>Loading...</p>}
      <div id="column-content">
        <div className="horizontal-menu">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link active"
                aria-current="page"
                href="#albisteak"
              >
                Albisteak
              </a>
            </li>
            <li className="nav-item">
              <button className="nav-link" onClick={() => setShowModal(true)}>
                Emaitzak/Egutegia
              </button>
            </li>
            <li className="nav-item">
              <button className="nav-link" onClick={() => setShowModal(true)}>
                Sailkapena
              </button>
            </li>
          </ul>
        </div>
        <div className="klub-news" id="albisteak">
          <div className="d-flex">
            {loadedKA &&
              dataKA.results.map((new_item, key) => (
                <Card key={key} item={new_item} setShowModal={setShowModal} />
              ))}
          </div>
        </div>
      </div>
      {loadedKA && (
        <Pagination
          page={page}
          setPage={setPage}
          setSearchParams={setSearchParams}
          page_size={page_size}
          count={dataKA.count}
          previous={dataKA.previous}
          next={dataKA.next}
        />
      )}
    </div>
  );
};

export default Club;

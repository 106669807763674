import React, { useState } from 'react';
// import { Container, Nav, Navbar as BootstrapNavbar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../config';
// import { useSelector } from 'react-redux';
import logo_tokikom from '../../theme/img/logo_tokikom.png';
// import i_club from '../../theme/img/i_club-access.svg';
// import i_app from '../../theme/img/i_app.svg';
import logo from '../../theme/img/logo.svg';
import { Collapse } from 'react-bootstrap';

const Navbar = () => {
  // const { langCode } = useSelector((state) => state.language);
  // const location = useLocation();
  const [openedMenu, setopenedMenu] = useState(false);
  return (
    <header id="mainheader" className="text-center text-sm-end">
      <div className="row g-0">
        <div className="col-sm-2 col-12 order-sm-1 order-2">
          <div
            id="header-column"
            className="d-flex justify-content-between g-0"
          >
            <div className="bg-secondary col-12 col-sm-10">
              <button
                className={`navbar-toggler ${openedMenu ? '' : 'collapsed'}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded={openedMenu}
                aria-label="Toggle navigation"
                onClick={() => setopenedMenu(!openedMenu)}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <Collapse in={openedMenu}>
                <div
                  className={`navbar-collapse collapse ${openedMenu && 'show'}`}
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item active border-bottom">
                      <Link
                        to={'/'}
                        onClick={() => setopenedMenu(false)}
                        className="nav-link"
                      >
                        <FormattedMessage id="ATARIA" defaultMessage="ATARIA" />
                      </Link>
                    </li>
                    <li className="nav-item active border-bottom">
                      <Link
                        to={`${ROUTES?.NEWS.path}`}
                        onClick={() => setopenedMenu(false)}
                        className="nav-link"
                      >
                        <FormattedMessage
                          id="AZKEN BERRIAK"
                          defaultMessage="AZKEN BERRIAK"
                        />
                      </Link>
                    </li>
                    {/* <li className="nav-item border-bottom">
                      <Link
                        to={`${ROUTES?.CLUBS.path}`}
                        onClick={() => setopenedMenu(false)}
                        className="nav-link"
                      >
                        <FormattedMessage id="KLUBAK" defaultMessage="KLUBAK" />
                      </Link>
                    </li> */}
                    {/* <li className="nav-item border-bottom">
                      <Link
                        to={`${ROUTES?.LIVES.path}`}
                        onClick={() => setopenedMenu(false)}
                        className="nav-link"
                      >
                        <FormattedMessage
                          id="ZUZENEAN"
                          defaultMessage="ZUZENEAN"
                        />
                      </Link>
                    </li> */}
                    {/* <li className="nav-item border-bottom">
                      <Link
                        to={`${ROUTES?.APP.path}`}
                        onClick={() => setopenedMenu(false)}
                        className="nav-link"
                      >
                        <img alt="Mugikor baten irudia" src={i_app} /> APP-a
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link
                        to={`${ROUTES?.HARREMANETARAKO.path}`}
                        onClick={() => setopenedMenu(false)}
                        className="nav-link"
                      >
                        <FormattedMessage
                          id="&gt; Harremanetarako"
                          defaultMessage="&gt; Harremanetarako"
                        />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={`${ROUTES?.ABOUT_US.path}`}
                        onClick={() => setopenedMenu(false)}
                        className="nav-link"
                      >
                        <FormattedMessage
                          id="&gt; Honi buruz"
                          defaultMessage="&gt; Honi buruz"
                        />
                      </Link>
                    </li>
                    <hr />
                    <img
                      alt="Tokikom taldeko logotipoa"
                      src={logo_tokikom}
                      width="132"
                      className="mt-4"
                    />
                  </ul>
                </div>
              </Collapse>
              {/* <a href="https://klubak.matx.eus/user/login">
                <img
                  src={i_club}
                  className="club-login"
                  alt="Kluben atalera sarbidea"
                />
              </a> */}
            </div>
            {/* <div className="bg-primary col-2">
              <Link
                to={`${ROUTES?.APP.path}`}
                onClick={() => setopenedMenu(false)}
              >
                <img alt="Mugikor baten irudia" src={i_app} /> APP-a
              </Link>
            </div> */}
          </div>
        </div>
        <div className="col-sm-10 col-12 order-sm-2 order-1">
          <div id="logo" className="p-3">
            <a id="portal-logo" title="Matx" href="/">
              <img src={logo} alt="Matx logoa" title="Matx" width="100px" />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Navbar;

import { getZuzenekoa } from '../api/matx';
import { Loader } from '../components';
import { useAsync } from '../hooks';
import React, { useEffect } from 'react';
// import { FormattedMessage } from 'react-intl';
// import { ROUTES } from '../config';
import { useParams } from 'react-router-dom';

const Live = () => {
  const { triggerFunction, data, loading, loaded } = useAsync();
  let { liveid } = useParams();

  useEffect(() => {
    triggerFunction(getZuzenekoa, liveid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFunction]);

  return (
    <div>
      <h1>{loaded && data.title}</h1>
      {loading && !loaded && <Loader />}

      {data && (
        <>
          {data.get_photos.map((image) => (
            <img
              src={image.image}
              alt={data.title}
              style={{ maxWidth: '200px' }}
            />
          ))}
          <h2>Partido:</h2>
          <ul>
            {Object.keys(data?.partido).map((k) => (
              <li key={k}>
                <strong>{k}:</strong>
                {JSON.stringify(data?.partido?.[k])}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default Live;

import React, {
  useEffect,
  // useState
} from 'react';
import Carousel from 'react-bootstrap/Carousel';
// import { Link } from 'react-router-dom';

import {
  getErredakziokoak,
  // getJaitsiMatx,
  getHedabideetakoak,
} from '../api/matx';
import {
  TeaserCard,
  NewsCard,
  // Modal
} from '../components';
// import { ROUTES } from '../config';
import { useAsync } from '../hooks';
// import b_appstore from '../theme/img/content-images/b_appstore.png';
// import b_Gplay from '../theme/img/content-images/b_Gplay.png';
// import bannerzuzenekoa from '../theme/img/content-images/bannerzuzenekoa.png';
// import imageapp from '../theme/img/content-images/image-app.png';
// import kirolaesku from '../theme/img/content-images/kirola-esku.jpg';
// import kirolafut from '../theme/img/content-images/kirola-fut.jpg';
// import kirolasas from '../theme/img/content-images/kirola-sas.jpg';
// import hasdadila from '../theme/img/content-images/hasdadila.png';
// import videoportada from '../theme/video/portadakoa.mp4';
// import { APP_READY } from '../config/constants';

const Home = () => {
  const {
    triggerFunction: triggerFunctionM,
    data: dataM,
    loaded: loadedM,
  } = useAsync();
  // const {
  //   triggerFunction: triggerFunctionJM,
  //   data: dataJM,
  //   loaded: loadedJM,
  // } = useAsync();

  const {
    triggerFunction: triggerFunctionH,
    data: dataH,
    loaded: loadedH,
  } = useAsync();
  useEffect(() => {
    triggerFunctionM(getErredakziokoak);
    // triggerFunctionJM(getJaitsiMatx);
    triggerFunctionH(getHedabideetakoak);
  }, [triggerFunctionM, triggerFunctionH]);
  const azken_berriak = loadedM
    ? dataM?.results?.reduce(function (result, value, index, array) {
        if (index % 2 === 0) result.push(array.slice(index, index + 2));
        return result;
      }, [])
    : [];
  const hedabide_albisteak = loadedH ? dataH.results.slice(0, 4) : [];
  // const [showModal, setShowModal] = useState(false);
  return (
    <>
      {/* <Modal show={showModal} setShowModal={setShowModal} /> */}
      {/* <section className="promo-band">
        <video
          id="portadakoa"
          width="100%"
          height="auto"
          autoPlay={true}
          loop={true}
          muted
        >
          <source src={videoportada} type="video/mp4"></source>
        </video>
        <img alt="Matx, has dadila partida" src={hasdadila} />
      </section> */}
      {/* <section className="promo-appa">
        <img
          alt="Mugikor baten irudia Matx aplikazioa pantailan duela"
          src={imageapp}
          className="promo-image"
        />
        {loadedJM && (
          <div dangerouslySetInnerHTML={{ __html: dataJM.get_body }} />
        )}
        {APP_READY && loadedJM && (
          <div className="estekak">
            {dataJM.google_play?.download_url && (
              <a href={dataJM.google_play?.download_url}>
                <img
                  alt="Deskargatu Matx aplikazioa Google play bidez"
                  src={b_Gplay}
                />
              </a>
            )}
            {dataJM.app_store?.download_url && (
              <a href={dataJM.app_store?.download_url}>
                <img
                  alt="Deskargatu Matx aplikazioa App store bidez"
                  src={b_appstore}
                />
              </a>
            )}
          </div>
        )}
      </section> */}
      <section className="news-home">
        <h2 className="titlehome">Azken berriak</h2>
        {loadedM && (
          <div className="matx-news mb-3">
            <Carousel slide={true} controls={true}>
              {azken_berriak.map((news_pair) => (
                <Carousel.Item>
                  <div className="row">
                    {news_pair.map((new_item, key) => {
                      return (
                        <div
                          className={`col-md-6 ${
                            key === 0 ? 'left-teaser' : 'right-teaser'
                          }`}
                        >
                          <TeaserCard
                            item={new_item}
                            // setShowModal={setShowModal}
                          />
                        </div>
                      );
                    })}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        )}
        <section id="hedabide-abisteak">
          <div className="row">
            {loadedH &&
              hedabide_albisteak.map((new_item, key) => (
                <div className="col-md-3">
                  <NewsCard item={{ ...new_item, key: key }} />
                </div>
              ))}
          </div>
        </section>
      </section>
      {/* <section className="banner">
        <Link to={`${ROUTES?.LIVES.path}`}>
          <img alt="Zuzenean" src={bannerzuzenekoa} />
        </Link>
      </section> */}
      {/* <section className="klubak-home">
        <h2 className="titlehome">Klubak</h2>
        <div className="d-flex kirolak">
          <div className="kirola-image">
            <Link to={`${ROUTES?.CLUBS.path}`}>
              <img alt="Saskibaloia" src={kirolasas} />
            </Link>
          </div>
          <div className="kirola-image">
            <Link to={`${ROUTES?.CLUBS.path}`}>
              <img alt="Futbola" src={kirolafut} />
            </Link>
          </div>
          <div className="kirola-image">
            <Link to={`${ROUTES?.CLUBS.path}`}>
              <img alt="izena" src={kirolaesku} />
            </Link>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Home;

import React from 'react';
// Eslint disables the following line
// import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/global.scss';
import { Navbar, Footer } from './components';

// import { Container } from 'react-bootstrap';
import { IntlProvider } from 'react-intl';
// import Routes from './Routes';
import './formatjs-polyfills';
import messages_eu from './locales/eu/eu.json';

import { useSelector } from 'react-redux';
import initialLoader from './helpers/hocs/initialLoader';
import { Outlet } from 'react-router-dom';

const messages = {
  eu: messages_eu,
};

function App() {
  const { langCode } = useSelector((state) => state.language);

  return (
    <IntlProvider locale={langCode} messages={messages[langCode]}>
      <Navbar />
      <main id="content">
        <Outlet />
      </main>
      <Footer />
    </IntlProvider>
  );
}

export default initialLoader(App);

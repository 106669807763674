import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import App from './App';
import { INDEX_COMPONENT, ROUTES } from './config';
import { TRACKING_ID } from './config';

ReactGA.initialize(TRACKING_ID);
const AppRoutes = () => {
  const location = useLocation();
  // const { langCode } = useSelector((state) => state.language);
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<INDEX_COMPONENT />} />
        {Object.values(ROUTES).map((route, key) => {
          return (
            <Route key={key} path={route.path} element={<route.Component />} />
          );
        })}
        {/* {Object.values(ROUTES).map((route) => {
          return Object.values(route.paths).map((path, key) => {
            return (
              <Route key={key} path={path} element={<route.Component />} />
            );
          });
        })} */}
      </Route>
    </Routes>
  );
};
export default AppRoutes;

import { getZuzenekoak } from '../api/matx';
import { useAsync } from '../hooks';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import banner_zuzenkoa_bidali from '../theme/img/content-images/banner-zuzenekoa-bidali.jpg';
import { FeaturedLive, LiveCard, Loader } from '../components';
import { ROUTES } from '../config';
import { Link } from 'react-router-dom';
import { ZUZENEKOAK_READY } from '../config/constants';
import zuzenean_placeholder from '../theme/img/content-images/Zuzenean.jpg';

const Lives = () => {
  const { triggerFunction, data, loading, loaded } = useAsync();
  // const page_size = 10;
  // const [page, setPage] = useState(1);

  useEffect(() => {
    triggerFunction(getZuzenekoak);
  }, [triggerFunction]);

  // useEffect(() => {
  //   triggerFunction(getZuzenekoak, page);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page]);

  return (
    <div>
      <h1 className="documentFirstHeading">
        <FormattedMessage id="ZUZENEAN" defaultMessage="ZUZENEAN" />
      </h1>
      <div id="column-content">
        {ZUZENEKOAK_READY ? (
          <>
            {loaded && (
              <FeaturedLive
                live={
                  data.results.filter((live) => live?.partido?.jugado === 1)[0]
                }
              />
            )}
            <h3>
              <FormattedMessage id="Hurrengoak:" defaultMessage="Hurrengoak:" />
            </h3>
            <div className="overflow-container mb-4">
              <div className="">
                {loading && !loaded && <Loader />}
                {loaded &&
                  data.results
                    .filter((live) => live?.partido?.jugado === 0)
                    .map((live, key) => {
                      return <LiveCard key={key} live={live} />;
                    })}
              </div>
            </div>
            <h3>
              <FormattedMessage id="Aurrekoak:" defaultMessage="Aurrekoak:" />
            </h3>
            <div className="overflow-container mb-4">
              <div className="">
                {loading && !loaded && <Loader />}
                {loaded &&
                  data.results
                    .filter((live) => live?.partido?.jugado === 1)
                    .slice(1)
                    .map((live, key) => {
                      return <LiveCard key={key} live={live} />;
                    })}
              </div>
            </div>
            <div className="banner my-3 my-lg-5 text-center">
              <Link to={`/${ROUTES.HARREMANETARAKO.path}`}>
                <img
                  alt="Irudi bat honako textuarekin: Zure taldearen zuzenekoa hemen ikusi dadin nahi baduzu, jarri gurekin harremanetan!"
                  src={banner_zuzenkoa_bidali}
                />
              </Link>
            </div>
          </>
        ) : (
          <>
            <h2>Laster zuekin!</h2>
            <img src={zuzenean_placeholder} alt="Bideo player baten irudia" />
          </>
        )}
      </div>
    </div>
  );
};

export default Lives;

import React from 'react';
// import i_twitter from '../../theme/img/content-images/i_twitter.svg';
import i_tictoc from '../../theme/img/content-images/i_tictoc.svg';
import i_insta from '../../theme/img/content-images/i_insta.svg';
import logo_gipuzkoakirolak from '../../theme/img/content-images/logo_gipuzkoakirolak.png';
import logo_jaurlaritza from '../../theme/img/content-images/logo_jaurlaritza.png';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../config';

const Footer = () => {
  return (
    <footer id="mainfooter">
      <div className="row">
        <div className="col-md-6">
          <address>
            Otalora Lizentziaduna 31 20500 Arrasate (Gipuzkoa) - Tel.: 943 25 05
            05
          </address>
          <p>
            <a href="#a">Harremanetarako</a> |{' '}
            <Link to={`${ROUTES?.LEGEOHARRA.path}`}>Lege oharra</Link>
          </p>

          <ul className="social">
            {/* <li>
              <a href="#a">
                <img alt="Twitter-eko logoa" src={i_twitter} />
              </a>
            </li> */}
            <li>
              <a
                target="_blank"
                href="https://www.tiktok.com/@matxapp2"
                rel="noreferrer"
              >
                <img alt="TikTok-eko logoa" src={i_tictoc} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/matxapp/"
                rel="noreferrer"
              >
                <img alt="Instagram-eko logoa" src={i_insta} />
              </a>
            </li>
          </ul>
        </div>
        <div className="col-md-6 my-3 my-lg-0 text-lg-end">
          <img alt="Gipuzkoa kirolak-eko logoa" src={logo_gipuzkoakirolak} />
          <img alt="Eusko Jaurlaritzako logoa" src={logo_jaurlaritza} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
